import { isEmpty } from "lodash";

export const INCIDENT_STATUS_QA_APPROVED = 31;
export const TICKET_SAVED_SUCCESSFULLY_MESSAGE = 'Ticket created successfully. Please check your email.';

export const API_RESPONSE_STATUSES = {
  SUCCESS: 200,
  UNAUTHORISED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

export const FILE_UPLOAD_STATUS = {
  PENDING: 1,
  SUCCESS: 2,
  FAILED: 3,
};

export const OPEN_API_VERSION = '3.0.1';

export const YES = 'Yes';
export const NO = 'No';
export const OK = 'Ok';
export const NOT_APPLICABLE = 'N/A'
//this decides idle timeout for application
export const IDLE_TIMER_TIMEOUT = 15 * 60 * 1000; // milliseconds
export const IDLE_TIMER_THROTTLE = 500;
//Setting below decides amount time in milliseconds. Other tabs are notified about the timeout on active tab.
export const IDLE_TIMER_SYNC_TIMERS = 100;

export const IDLE_TIMEOUT_MESSAGE = 'For security reasons, you have been logged out due to inactivity.';

//assessment list
export const REMEDIATION_STAGE = 'Remediation';
export const ACTIVE_STAGE = 'Active';
export const COMPLETED_STAGE = 'Completed';

export const ASSESSMENT_LIST_STEPS = [
  {
    AssessmentStageId: 1,
    AssessmentStageName: ACTIVE_STAGE,
  },
  {
    AssessmentStageId: 2,
    AssessmentStageName: REMEDIATION_STAGE,
  },
  {
    AssessmentStageId: 3,
    AssessmentStageName: COMPLETED_STAGE,
  },
];

export const QUEUED = 1;
export const EXPEDITED = 2;
export const ACTIVE = 3;
export const RESERVED = 4;
export const REMEDIATION = 5;
export const COMPLETED = 6;

export const ESCALATED_DELIVERY_STATUS = 206450004;

export const APP_CONFIG_KEYS = {
  ASSESSMENTS_OVERVIEW_EVIDENCE_SUFFIX: 'ASSESSMENTS_OVERVIEW_EVIDENCE_SUFFIX',
  ASSESSMENTS_OVERVIEW_RESULT_SUFFIX: 'ASSESSMENTS_OVERVIEW_RESULT_SUFFIX',
  ASSESSMENT_REQUEST_MAX_FILE_SIZE: 'ASSESSMENT_REQUEST_MAX_FILE_SIZE',
  CORL_EMAIL_VSRM: 'CORL_EMAIL_VSRM',
  ASSESSMENT_REQUEST_MAX_FILES_TO_UPLOAD: 'ASSESSMENT_REQUEST_MAX_FILES_TO_UPLOAD',
  DISPLAY_ZENDESK_STARTED_GUIDE: 'DISPLAY_ZENDESK_STARTED_GUIDE',
  DISPLAY_ZENDESK_VIDEO_WALKTHROUGHS: 'DISPLAY_ZENDESK_VIDEO_WALKTHROUGHS',
  DISPLAY_ZENDESK_SUPPORT_ARTICLES: 'DISPLAY_ZENDESK_SUPPORT_ARTICLES',
  DISPLAY_DEVELOPER_PORTAL: 'DISPLAY_DEVELOPER_PORTAL',
};

//Pagination Constants
export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_ROWS_PER_PAGE = 25;

//HTTP code constants
export const HTTP_200_OK = 200;
export const ACCESS_DENIED = 'Access Denied';

export const LOGOUT_MESSAGE = 'Are you sure you want to logout?';

//Executive Summary
export const DISCLAIMER = `This document is PROPRIETARY and CONFIDENTIAL Information. It may not be used, disclosed or reproduced, in whole or in part, without the express
written permission of CORL Technologies, LLC. While every care has been taken in preparing the information published in this document, CORL
Technologies LLC (CORL) does not guarantee the accuracy or currency of the content researched. The research is provided on an as-is basis without
warranties of any kind, either expressed or implied. CORL shall not be held responsible for any errors or omissions and accepts no liability whatsoever for
any loss or damage howsoever arising. The use of this document is subject to the Terms of Service located at`;

//Stakeholder Lookup Values
export const STAKEHOLDER_DISABLED = 1;
export const STAKEHOLDER_ENABLED = 2;

export const RESULTS_EVIDENCE_LINK_DISABLED = 0;
export const RESULTS_EVIDENCE_LINK_ENABLED = 1;

export const CORL_OWNER = 1;
export const CLIENT_OWNER = 2;
export const VENDOR_OWNER = 3;

export const TRUE = '1';
export const FALSE = '2';
//contract config values
export const TPIR_SUBSCRIBED = {
  NO: 0,
  YES: 1,
};
export const REMEDIATION_ENABLED = {
  NO: 0,
  YES: 1,
}
export const EXECUTIVE_SUMMARY = {
  NO: 1,
  YES: 2,
};
//AssessmentType
export const ASSESSMENT_TYPE_STANDARD = 'standard';
export const ASSESSMENT_TYPE_CORL_CLEARED = 'corl cleared';

// Risk Rating Overview Executive Summary
export const DATA_ACCESS = 'OFFSHORE ACCESS';
export const DATA_STORAGE = 'OFFSHORE STORAGE';
export const FOURTH_PARTY_SUBCONTRACTOR = '4TH-PARTY SUBCONTRACTOR';

//Validation Evidence Review Executive Summary
export const ADEQUATE = 'adequate';
export const INADEQUATE = 'inadequate';

//ES Feedback
export const DOES_NOT_MEET_EXPECTATIONS = '1';
export const MEET_EXPECTATIONS_AREA_FOR_IMPROVEMENT = '2';
export const MEETS_EXPECTATIONS = '3';

//Command Center Query Params
export const VENODR_METRICS = 'vendor-metrics';
export const CLIENT_METRICS = 'client-metrics';
export const EXECUTIVE_SUMMARY_METRICS = 'exec-summary-metrics';
export const FULL_LIST_VIEW = 'full-list-view';
export const ESCALATED_ASSESSMENTS_VIEW = 'escalated-assessments-view';

export const DISABLED = 1;
export const ENABLED = 2;

//The time window before which token will be updated (in seconds)
export const TOKEN_UPDATE_TIME_WINDOW = 900;

//The time interval to check for the token expiry (in miliseconds)
export const TOKEN_CHECK_TIME_INTERVAL = 300 * 1000;

export const ERROR_ROUTES = ['/error', '/logout', '/unauthorized'];

//NavBar menu constants
export const PROGRAM_METRICS = "program metrics"
export const REQUEST_ASSESSMENT = "request assessment"
export const ASSESSMENT_OVERVIEW = "assessments overview"
export const INCIDENTS_TAB = "incidents"
export const REPORT_INCIDENT_BUTTON = "+ report incident"

//Legacy and protal 2.0 constants
export const DYNAMIC = 'Dynamic';
export const PORTAL_2 = 'Portal 2.0';

export const NEW_NOTIFICATION_STATUS_ID = 1;
export const READ_NOTIFICATION_STATUS_ID = 2;
export const DISMISS_NOTIFICATION_STATUS_ID = 3;

export const YES_VALUE = 1;
export const NO_VALUE = 0;

export const TERMS_AND_CONDITIONS_CORL_URL_PATH = 'https://corltech.com/vendor-software-terms-of-service/';
export const ABOUT_US_CORL_URL_KEY = 'AboutUsURL';
export const ASSESSMENT_STANDARD = "Standard"
export const ASSESSMENT_CORL_CLEARED_CLIENT_INITIATED = "CORL Cleared Client Initiated"
export const ASSESSMENT_CLIENT_INITIATED = "Client Initiated"

export const FOOTER_TECHNICAL_CONTACT_US_EMAIL_KEY = 'FooterTechnicalContactUsEmail';
export const FOOTER_LEGAL_CONTACT_US_EMAIL_KEY = 'FooterLegalContactUsEmail';

export const getContactUsTechnicalSupportLink = (CUSTOMER_SUPPORT_EMAIL, orgName) => {
  if (isEmpty(CUSTOMER_SUPPORT_EMAIL) || isEmpty(orgName)) {
    return ''
  }
  return `mailto:${CUSTOMER_SUPPORT_EMAIL}?subject=${orgName} Has a Support Question`;
}
export const getContactUsLegalLink = (LEGAL_ENQUIRY_EMAIL, orgName) => {
  if (isEmpty(LEGAL_ENQUIRY_EMAIL) || isEmpty(orgName)) {
    return ''
  }
  return `mailto:${LEGAL_ENQUIRY_EMAIL}?subject=${orgName} Has a Legal Question`;
}

export const ASSESSMENT_TERMINATED = 1;
export const EXPEDITE_ASSESSMENT = 2;
export const ESCALATED_ASSESSMENT = 3;
export const ASSESSMENT_PAUSED = 4;
export const COMPLETED_ASSESSMENT = 6;
export const ASSESSMENT_REMOVE_EXPEDITE = 8;
export const ASSESSMENT_CLOSED = 9;

export const DATE_TYPE_RESPONSE_ID = 3;

export const DATE_FORMAT = "MM/dd/yyyy";
export const DATE_W_TIME_FORMAT = "MM/dd/yyyy @ hh:mm a";
export const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';

export const PRE_ASSESSMENT = 3;
